import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SectionTop from '../components/section-top'
import ContentWrapper from '../components/content-wrapper'
import Card from '../components/card'
import { Arrow } from '../components/icons'

const PageIntro = styled(ContentWrapper)`
  margin-top: 10rem;
  margin-bottom: 5rem;

  @media (min-width: 48em) {
    margin-top: 20rem;
    margin-bottom: 12rem;
  }

  @media (min-width: 62.5em) {
    margin-bottom: 15rem;
  }
`

const Heading = styled.h2`
  font-size: 2.4rem;
  margin: 4rem 0 1.6rem;

  @media (min-width: 48rem) {
    margin: 7rem 0 1.6rem;
  }
`

const PressReleases = styled.ul`
  list-style: none;
  margin: 0 0 10rem;
  padding: 0;
`

const PressRelaseLink = styled(Link)`
  display: block;
  color: currentColor;
  background: var(--color-white);
  display: flex;
  align-items: center;
  border-radius: 2rem;
  padding: 2rem;
  margin: 0 0 0.4rem;

  @media (min-width: 48rem) {
    padding: 2rem 3.2rem;
  }
`

const Date = styled.div`
  margin-right: 2rem;
  white-space: nowrap;

  @media (min-width: 48rem) {
    display: inline-block;
    margin-right: 2rem;
  }

  @media (min-width: 62.6rem) {
    margin-right: 11rem;
  }
`

const Title = styled.span`
  flex-grow: 1;
`

const Icon = styled.span`
  background: var(--color-secondary-3);
  width: 4.6rem;
  height: 4.6rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-left: 1rem;
`

const Page = ({
  data: {
    contentfulContactAndPressPage: { title },
    allContentfulPressRelease: { nodes: pressReleases },
  },
}) => {
  return (
    <Layout showPattern={true}>
      <SEO title={title} />

      <PageIntro>
        <PageIntro>
          <SectionTop title={title} as="h1" wide={true} />
        </PageIntro>
      </PageIntro>

      <ContentWrapper>
        <Heading>Nyheter</Heading>

        <PressReleases>
          {pressReleases.map(({ id, title, date, slug }) => (
            <li key={id}>
              <PressRelaseLink to={`/kontakt-och-nyheter/${slug}`}>
                <Title>
                  <Date>{date}</Date> {title}
                </Title>
                <Icon>
                  <Arrow width="18" height="18" />
                </Icon>
              </PressRelaseLink>
            </li>
          ))}
        </PressReleases>

        <Heading>Kontakt</Heading>

        <Card
          hideHeading={true}
          rightAdjustMedia={true}
          backgroundColor="Sand"
          text={{
            raw:
              '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Är det något du undrar över? Kontakta oss på ","marks":[],"data":{}},{"nodeType":"hyperlink","data":{"uri":"mailto:info@kvarteretyxan.se"},"content":[{"nodeType":"text","value":"info@kvarteretyxan.se","marks":[],"data":{}}]},{"nodeType":"text","value":"\\n","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"","marks":[],"data":{}}]}]}',
          }}
          media={[
            {
              id: '087d412d-e9eb-5340-a086-77ba6aa69c8f',
              fluid: {
                base64:
                  'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wAARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUEBwj/xAAmEAACAgEEAgAHAQAAAAAAAAABAgMEEQAFBhIhMQcTIzJRcYHR/8QAFQEBAQAAAAAAAAAAAAAAAAAABAb/xAAeEQABBQACAwAAAAAAAAAAAAACAAEDBBEFUSExkf/aAAwDAQACEQMRAD8Ac8L4G8u40qUm9SXKHzWiivQv9BQn3N26+ScqAMNk9/eNW5yThlH4S1qhrwtuEtyBxm/YWVVeIJ0Cqg8FsgeBk4AyBnTzgez0DWsFqVdz2zl4gx8nsfY/PnTHlFWCanaR4IyBGcYQAj9H+anKvHRW6hSZhFuP1iEIDH6bys6cw27jO973JY3ZpLdzqql2mevgewAgbwBn/dGpHJGWvu0sawVnCkgGWukjYBPtmBJ/p0ako7kwAwiWMyG5ydt8X//Z',
                aspectRatio: 1.7786561264822134,
                src:
                  '//images.ctfassets.net/bgezx1q1l95w/AyMvlsNlfQ2zb7lcIS82g/4ed6f90acdde98a1edec4ed0b263794b/MI_bdVBA-_okb6Bm0A_.jpg?w=800&q=50',
                srcSet:
                  '//images.ctfassets.net/bgezx1q1l95w/AyMvlsNlfQ2zb7lcIS82g/4ed6f90acdde98a1edec4ed0b263794b/MI_bdVBA-_okb6Bm0A_.jpg?w=200&h=112&q=50 200w,\n' +
                  '//images.ctfassets.net/bgezx1q1l95w/AyMvlsNlfQ2zb7lcIS82g/4ed6f90acdde98a1edec4ed0b263794b/MI_bdVBA-_okb6Bm0A_.jpg?w=400&h=225&q=50 400w,\n' +
                  '//images.ctfassets.net/bgezx1q1l95w/AyMvlsNlfQ2zb7lcIS82g/4ed6f90acdde98a1edec4ed0b263794b/MI_bdVBA-_okb6Bm0A_.jpg?w=800&h=450&q=50 800w,\n' +
                  '//images.ctfassets.net/bgezx1q1l95w/AyMvlsNlfQ2zb7lcIS82g/4ed6f90acdde98a1edec4ed0b263794b/MI_bdVBA-_okb6Bm0A_.jpg?w=900&h=506&q=50 900w',
                srcWebp:
                  '//images.ctfassets.net/bgezx1q1l95w/AyMvlsNlfQ2zb7lcIS82g/4ed6f90acdde98a1edec4ed0b263794b/MI_bdVBA-_okb6Bm0A_.jpg?w=800&q=50&fm=webp',
                srcSetWebp:
                  '//images.ctfassets.net/bgezx1q1l95w/AyMvlsNlfQ2zb7lcIS82g/4ed6f90acdde98a1edec4ed0b263794b/MI_bdVBA-_okb6Bm0A_.jpg?w=200&h=112&q=50&fm=webp 200w,\n' +
                  '//images.ctfassets.net/bgezx1q1l95w/AyMvlsNlfQ2zb7lcIS82g/4ed6f90acdde98a1edec4ed0b263794b/MI_bdVBA-_okb6Bm0A_.jpg?w=400&h=225&q=50&fm=webp 400w,\n' +
                  '//images.ctfassets.net/bgezx1q1l95w/AyMvlsNlfQ2zb7lcIS82g/4ed6f90acdde98a1edec4ed0b263794b/MI_bdVBA-_okb6Bm0A_.jpg?w=800&h=450&q=50&fm=webp 800w,\n' +
                  '//images.ctfassets.net/bgezx1q1l95w/AyMvlsNlfQ2zb7lcIS82g/4ed6f90acdde98a1edec4ed0b263794b/MI_bdVBA-_okb6Bm0A_.jpg?w=900&h=506&q=50&fm=webp 900w',
                sizes: '(max-width: 800px) 100vw, 800px',
              },
            },
          ]}
        />
      </ContentWrapper>
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object,
}

export default Page

export const pressPageQuery = graphql`
  query ContactPage {
    contentfulContactAndPressPage {
      title
    }
    allContentfulPressRelease(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        slug
        date
      }
    }
  }
`
